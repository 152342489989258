import React from 'react'
import { graphql } from 'gatsby'

import SEO from '~/components/seo'

import {
  ProductTitle,
  ProductDescription,
  Head,
  Thumbnail,
  ProductInfoWrapper,
} from './styles'
import ProductForm from '../../components/ProductForm'
import BackgroundImage from 'gatsby-background-image'
import Recommend from '../../components/Recommend'
import CategoryList from '../../components/CategoryLink'
import PageTransitionWhite from '../../components/Animation/PageTransitionWhite'

const ProductPage = ({ data }) => {
  const product = data.shopifyProduct
  return (
    <>
      <SEO title={product.title} description={product.description} />

      <ProductInfoWrapper>
        <Head>
          <Thumbnail>
            {product.images.map(image => {
              return image.localFile ? (
                <BackgroundImage
                  Tag="section"
                  key={image.id}
                  fluid={image.localFile.childImageSharp.fluid}
                  style={{
                    paddingBottom: `75%`,
                    flexShrink: '0',
                    width: `80%`,
                    marginRight: `4px`,
                    backgroundSize: `cover`,
                    backgroundColor: `#f7f7f7`,
                  }}
                />
              ) : null
            })}
          </Thumbnail>
          <ProductTitle>{product.title}</ProductTitle>
          <ProductForm product={product} />
        </Head>

        <ProductDescription
          dangerouslySetInnerHTML={{ __html: product.descriptionHtml }}
        />
      </ProductInfoWrapper>
      <Recommend />
      <CategoryList />
      <PageTransitionWhite />
    </>
  )
}

export const query = graphql`
  query($handle: String!) {
    shopifyProduct(handle: { eq: $handle }) {
      id
      title
      handle
      productType
      description
      descriptionHtml
      shopifyId
      options {
        id
        name
        values
      }
      variants {
        id
        title
        price
        availableForSale
        shopifyId
        selectedOptions {
          name
          value
        }
      }
      priceRange {
        minVariantPrice {
          amount
          currencyCode
        }
        maxVariantPrice {
          amount
          currencyCode
        }
      }
      images {
        originalSrc
        id
        localFile {
          childImageSharp {
            fluid(maxWidth: 910) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }
  }
`

export default ProductPage

import styled from '@emotion/styled'
import color from '../../styles/color'
import colors from '../../styles/color'
import { breakpoints } from '../../utils/styles'

export const Price = styled.div`
  font-size: 15px;
  color: #1c1c1c;
  text-align: center;
  margin-bottom: 16px;

  @media (min-width: ${breakpoints.m}px) {
    margin-bottom: 30px;
  }
`

export const PriceSelectContainer = styled.div`
  margin-left: 50px;
  display: flex;
  flex-direction: column;

  label {
    font-size: 14px;
    margin-right: 16px;
    margin-bottom: 4px;
    color: ${color.GRAY};

    @media (min-width: ${breakpoints.m}px) {
      margin-bottom: 0;
    }
  }

  @media (min-width: ${breakpoints.m}px) {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }
`

export const PriceSelect = styled.div`
  position: relative;
  width: 90px;

  &::after {
    content: '';
    position: absolute;
    top: calc(50% - 1px);
    right: 10px;
    width: 5px;
    height: 5px;
    border-top: 1px solid ${colors.GRAY};
    border-right: 1px solid ${colors.GRAY};
    transform: translateY(-50%) rotate(135deg);
  }

  select {
    position: relative;
    width: 90px;
    padding: 5px 5px 5px 10px;
    border-radius: 0;
    -webkit-appearance: none;
    background-color: ${colors.WHITE_GRAY};
  }
`

export const CartLink = styled.div`
  font-size: 15px;
  text-align: center;
  margin-bottom: 60px;
  a {
    color: ${color.GRAY};
    border-bottom: 1px solid ${color.GRAY};
  }
`

export const Attention = styled.div`
  text-align: center;
  color: ${color.RED};
  margin-bottom: 24px;
  font-size: 14px;
`

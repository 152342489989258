import styled from '@emotion/styled'
import colors from '../../../styles/color'
import { Z_INDEX_PAGE_TRANSITION } from '../../../const'
import { keyframes } from '@emotion/core'

const fadeOut = keyframes`
  0% {
    opacity: 0.8;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
`

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: ${colors.WHITE};
  animation: ${fadeOut} 0.6s cubic-bezier(0.6, 0.07, 0.8, 0.99);
  animation-fill-mode: forwards;
  z-index: ${Z_INDEX_PAGE_TRANSITION};
`

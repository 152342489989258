import styled from '@emotion/styled'
import { breakpoints } from '../../utils/styles'
import colors from '../../styles/color'

export const ProductInfoWrapper = styled.div`
  @media (min-width: ${breakpoints.m}px) {
    display: flex;
    background-color: ${colors.WHITE_GRAY};
    margin-bottom: 50px;
  }
`

export const Head = styled.div`
  background-color: #f7f7f7;
  padding-bottom: 5px;
  padding-top: 33px;
  @media (min-width: ${breakpoints.m}px) {
    width: 100%;
    margin-bottom: 100px;
  }
`

export const Thumbnail = styled.div`
  display: flex;
  align-items: center;
  overflow: scroll;
  overflow-scrolling: touch;
  margin-bottom: 50px;

  &::-webkit-scrollbar {
    display: none;
  }
`

export const ProductTitle = styled.h1`
  font-size: 15px;
  color: #1c1c1c;
  text-align: center;
  border-bottom: 1px solid #707070;
  padding: 0 16px 12px;
  width: 80%;
  margin: 0 auto 12px;
`

export const ProductDescription = styled.div`
  font-size: 15px;
  color: #707070;
  padding: 0 12px;
  margin-bottom: 55px;

  @media (min-width: ${breakpoints.m}px) {
    padding: 30px;
    width: 80%;
  }
`
